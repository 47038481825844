import { useMemo, useState } from "react";
import Text from "../../../../components/common/Text";
import { GRAY_5A6E7B, GRAY_BCCCD6 } from "../../../../constants/colors";
import { useGetError } from "../../../../services/error/error-query";
import {
  ContainerDetail,
  ContainerErrorCode,
  ContainerTitle,
  StyledImage,
} from "./style";
import { ErrorRenderType } from "./type";
import ReactDOMServer from "react-dom/server";
import ImagePlaceholder from "../../../../components/common/Placeholder/image";
import { useApplication } from "../../../../App.context";
import { getLang } from "../../../../utils/helper";

const Content = () => {
  const { appTheme } = useApplication();
  const { data: dataError } = useGetError();
  const lang = getLang().toLowerCase();
  const error = useMemo(() => {
    let error: Partial<ErrorRenderType> = {};
    if (dataError) {
      const { status, data } = dataError;
      console.log("dataError", dataError);
      const { exp, code, message } = data || {};

      if (lang === "en") {
        if (status && status === 502) {
          error = {
            title: "Unable to proceed to the test at the moment.",
            detail: "Please try again later.",
            isErrorCode: true,
            errorCode: "9999999",
            errorCodeVoice: "nine nine nine nine nine nine nine",
            errorMessage: "Service Timeout",
            errorImage: appTheme.questionnaire.error_image_url,
          };
        } else if (exp && exp === "token expired") {
          error = {
            title: "Session Timeout",
            detail: "You can try retaking the test.",
            isErrorCode: false,
            errorImage: appTheme.questionnaire.warning_image_url,
          };
        } else {
          error = {
            title: "Unable to proceed to the test at the moment.",
            detail: "Please try again later.",
            isErrorCode: true,
            errorCode: code || "9999999",
            errorCodeVoice: `${code}` || "nine nine nine nine nine nine nine",
            errorMessage: message || "Unknown Error",
            errorImage: appTheme.questionnaire.error_image_url,
          };
        }
      } else {
        if (status && status === 502) {
          error = {
            title: "ไม่สามารถทำแบบทดสอบได้ในขณะนี้",
            detail: "กรุณาลองใหม่อีกครั้งภายหลัง",
            isErrorCode: true,
            errorCode: "9999999",
            errorCodeVoice: "nine nine nine nine nine nine nine",
            errorMessage: "Service Timeout",
            errorImage: appTheme.questionnaire.error_image_url,
          };
        } else if (exp && exp === "token expired") {
          error = {
            title: "หมดเวลาทำแบบทดสอบ",
            detail: "คุณสามารถทำใหม่ได้อีกครั้ง",
            isErrorCode: false,
            errorImage: appTheme.questionnaire.warning_image_url,
          };
        } else {
          error = {
            title: "ไม่สามารถทำแบบทดสอบได้ในขณะนี้",
            detail: "กรุณาลองใหม่อีกครั้งภายหลัง",
            isErrorCode: true,
            errorCode: code || "9999999",
            errorCodeVoice: "nine nine nine nine nine nine nine",
            errorMessage: message || "Unknown Error",
            errorImage: appTheme.questionnaire.error_image_url,
          };
        }
      }
    }
    return error;
  }, [dataError]);

  const imagePlaceholder = ReactDOMServer.renderToString(
    <ImagePlaceholder
      bg={appTheme?.questionnaire.placeholder_color_1}
      fg={appTheme?.questionnaire.placeholder_color_2}
    />
  );

  return (
    <>
      <StyledImage
        src={error.errorImage ? error.errorImage : ""}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = `data:image/svg+xml,${encodeURIComponent(
            imagePlaceholder
          )}`;
        }}
        alt={`${error.title} ${error.detail}`}
      />
      <ContainerTitle>
        <Text type="Bold" size={"20px"} lineHeight={"30px"}>
          {error?.title}
        </Text>
      </ContainerTitle>
      <ContainerDetail>
        <Text
          weight={400}
          size={"16px"}
          lineHeight={"24px"}
          color={GRAY_5A6E7B}
        >
          {error?.detail}
        </Text>
      </ContainerDetail>
      {error?.isErrorCode && (
        <ContainerErrorCode>
          <Text
            weight={400}
            size={"12px"}
            lineHeight={"18px"}
            color={GRAY_BCCCD6}
            aria-label={error?.errorCodeVoice}
          >
            Error Code: {error?.errorCode} - {error?.errorMessage}
          </Text>
        </ContainerErrorCode>
      )}
    </>
  );
};

export default Content;
