import styled from "styled-components";
import { BLUE_E1F1FF } from "../../constants/colors";

export const Container = styled.div<{
  backgroundColor?: string;
}>`
  height: 100vh;
  width: 100vw;
  overflow: auto;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  padding-bottom: 98px;
`;

export const SubContainer = styled.div<{
  backgroundColor?: string;
}>`
  background: transparent !important;
  height: auto;
  width: 100vw;
  overflow: auto;
  padding: 0 16px 98px;
  box-sizing: border-box;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
`;
