import { Skeleton } from "@mui/material";
import { GRAY_F9FAFB } from "../../../constants/colors";
import { StyledButton, StyledFooter } from "./styled";
import { ButtonFooterProp, FooterProp } from "./type";
import { BLUE_C7E3F4 } from "../../../constants/colors";

export const ButtonFooter = (prop: ButtonFooterProp) => {
  const {
    children,
    isValid = true,
    sx,
    onClick,
    backgroundColor,
    isLoading = false,
    ...restProps
  } = prop;

  return isLoading ? (
    <Skeleton
      animation="wave"
      variant="rounded"
      sx={{
        bgcolor: BLUE_C7E3F4,
        borderRadius: "0.75rem",
        height: "48px",
        flexShrink: 0,
        width: "100%",
      }}
    ></Skeleton>
  ) : (
    <StyledButton
      sx={sx}
      type="submit"
      valid={isValid.toString()}
      disabled={!isValid}
      onClick={onClick}
      backgroundColor={backgroundColor}
      {...restProps}
    >
      {children}
    </StyledButton>
  );
};

const FooterComponent = (props: FooterProp) => {
  const { children, backgroundColor = GRAY_F9FAFB, isRelative = false } = props;
  return (
    <StyledFooter 
    backgroundColor={backgroundColor}
    isRelative={isRelative}>
      {children && children}
    </StyledFooter>
  );
};

export default FooterComponent;
