import { StyledCard, StyledImageTop } from "./style";
import { FlexCardProps } from "./type";

const FlexCard = (props: FlexCardProps) => {
  const {
    children,
    className,
    topImage,
    bgColor,
    fullHeight,
    marginX,
    marginY,
    paddingX,
    paddingY,
    paddingTop,
    boxShadow,
  } = props;
  return (
    <>
      {topImage && <StyledImageTop>{topImage}</StyledImageTop>}
      <StyledCard
        className={className}
        topImage={topImage}
        bgColor={bgColor}
        fullHeight={fullHeight}
        marginX={marginX}
        marginY={marginY}
        paddingX={paddingX}
        paddingY={paddingY}
        paddingTop={paddingTop}
        boxShadow={boxShadow}
      >
        {children}
      </StyledCard>
    </>
  );
};

export default FlexCard;
