import styled from "styled-components";
import { GRAY_F9FAFB } from "../../constants/colors";

export const Container = styled.div`
  background-color: ${GRAY_F9FAFB};
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;

  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;

  padding-top: 80px;
  text-align: center;
`;
