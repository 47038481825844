import { Divider } from "@mui/material";
import QuestionCard from "../../../../../../components/QuestionCard";
import {
  Container,
  ContainerBackground,
  ContainerCard,
  ContainerSkeleton,
  ContainerSkeletonCard,
} from "./styled";
import Skeleton from "@mui/material/Skeleton";
import { getQuestions } from "../../../../../../utils/helper";
import { useApplication } from "../../../../../../App.context";

const QuestionList = ({ isLoading }) => {
  const { appTheme } = useApplication();
  const data = getQuestions();
  const skeletonQuestions: any[] = [];
  for (let index = 0; index < 3; index++) {
    skeletonQuestions.push(
      <ContainerSkeletonCard>
        <Skeleton
          variant="rounded"
          width="100%"
          animation="wave"
          sx={{
            height: "16px",
            bgcolor: appTheme?.questionnaire.skeleton_color,
            maxWidth: "300px",
          }}
          style={{
            borderRadius: "6px",
          }}
        />
        <ContainerSkeleton>
          <Skeleton
            variant="rounded"
            animation="wave"
            width="100%"
            height={16}
            sx={{
              maxWidth: "64px",
              bgcolor: appTheme?.questionnaire.skeleton_color,
              marginRight: "1rem",
              borderRadius: "6px",
            }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            width="100%"
            height={16}
            sx={{
              maxWidth: "64px",
              bgcolor: appTheme?.questionnaire.skeleton_color,
              borderRadius: "6px",
            }}
          />
        </ContainerSkeleton>
        <Divider
          variant="middle"
          sx={{ borderColor: "#5A6E7B1A", margin: 0, height: "1px" }}
          aria-hidden="true"
        />
      </ContainerSkeletonCard>
    );
  }
  return (
    <Container>
      <ContainerBackground>
        <ContainerCard>
          {isLoading
            ? skeletonQuestions
            : data?.questions?.map((x: Question, index: number) => {
                return (
                  <QuestionCard
                    key={`${index}-${x.id}`}
                    {...x}
                    divider={index !== data?.questions?.length - 1}
                    index={index + 1}
                  />
                );
              })}
        </ContainerCard>
      </ContainerBackground>
    </Container>
  );
};

export default QuestionList;
