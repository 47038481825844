import styled from "styled-components";
import { GRAY_F9FAFB } from "../../constants/colors";

export const StyleForm = styled.form`
  height: 100%;
  display: flex;
  width: 100%;
`;

export const ContainerLoading = styled.div`
  height: 80vh;
  z-idnex: 100;
  background-color: ${GRAY_F9FAFB};
`;
