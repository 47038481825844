import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: auto;
`;

export const StyledLayoutText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ContainerImage = styled.div`
  border-radius: 16px;
  padding-top: 24px;
  max-width: 375px;
  padding-bottom: 65px;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 457px;
  border-radius: 16px;
  object-fit: fit;
`;
export const EmptySpace = styled.div`
  height: 48px;
  display: flex;
  flex-direction: column;
`;
