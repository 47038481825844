import { useCallback, ChangeEvent } from "react";
import { FormControl, InputLabel } from "@mui/material";
import { SelectFieldProps } from "./type";
import SelectMui, { SelectChangeEvent } from "@mui/material/Select";

const Select = (props: SelectFieldProps) => {
  const {
    value,
    onChange,
    children,
    name = "select-field",
    label,
    ...resetProp
  } = props;

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const { value } = event.target;
      onChange?.(value);
    },
    [onChange]
  );

  return (
    <FormControl component="fieldset">
      <InputLabel id={`${label}-select-type`}>{label}</InputLabel>
      <SelectMui
        labelId={`${label}-select-type`}
        label={label}
        name={name}
        value={value}
        {...resetProp}
        onChange={handleChange}
      >
        {children}
      </SelectMui>
    </FormControl>
  );
};

export default Select;
