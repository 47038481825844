import { Box } from "@mui/material";
import { ResultTextProps } from "./type";
import { StyledImage } from "./style";
import { GREEN_63BE00, RED_DB0000 } from "../../../constants/colors";
import Text from "../Text";

import CorrectIcon from "../../../constants/images/correct_icon.svg";
import InorrectIcon from "../../../constants/images/incorrect_icon.svg";

const ResultText = (props: ResultTextProps) => {
  const { isCorrect, text } = props;
  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
      <Box>
        {isCorrect ? (
          <StyledImage src={CorrectIcon} aria-hidden="true"/>
        ) : (
          <StyledImage src={InorrectIcon} aria-hidden="true"/>
        )}
      </Box>
      {text && (
        <Text
          type="Bold"
          weight={700}
          color={isCorrect ? GREEN_63BE00 : RED_DB0000}
          size="16px"
          lineHeight="24px"
          talk={text}
        >
          {text}
        </Text>
      )}
    </Box>
  );
};

export default ResultText;
