import { ThemeProvider } from "@mui/material/styles";

import { createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import VConsole from "vconsole";
import { CustomQueryClientProvider } from "./utils/helper";
import ApplicationProvider from "./App.context";
import Button from "./pages/Button";

const Theme = createTheme({
  typography: {
    fontFamily: "KrungthaiFast-Regular",
    button: {
      textTransform: "none",
    },
  },
});

const App = () => {
  useEffect(() => {
    if (process.env.REACT_APP_TOGGLE_VCONSOLE === "true") {
      new VConsole();
    }
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.title = "Survey";
  }, []);

  return (
    <BrowserRouter>
      <ApplicationProvider>
        <CustomQueryClientProvider>
          <ThemeProvider theme={Theme}>
            {/* <Button /> */}
            <Routes />
            <ReactQueryDevtools initialIsOpen={false} />
          </ThemeProvider>
        </CustomQueryClientProvider>
      </ApplicationProvider>
    </BrowserRouter>
  );
};

export default App;
