import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: auto;
`;

export const StyledLayoutText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin-bottom: 1rem;
`;

export const StyledLayoutTextQuestion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: left;
  margin-bottom: 1rem;
`;

export const ContainerImage = styled.div`
  border-radius: 16px;
  padding-top: 24px;
  max-width: 375px;
  padding-bottom: 65px;
`;

export const StyledImage = styled.object<{ fallbackImage?: boolean }>`
  width: ${({ fallbackImage }) => (fallbackImage ? "240px" : "210px")};
  height: ${({ fallbackImage }) => (fallbackImage ? "180px" : "158px")};
  max-height: 457px;
  object-fit: fill;
  margin: 0 auto;
  transform: ${({ fallbackImage }) =>
    fallbackImage ? "translateY(9.2%)" : ""};
  display: block;
`;
export const EmptySpace = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLayoutTextContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
export const StyledLayoutTextResult = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;
export const StyledSkeletonResult = styled.div`
  display: flex;
`;
