import { makeField } from "./tools";
import RadioGroup from "../common/RadioGroup";
import TextFieldMui from "../common/TextField";
import { RadioGroupProps } from "../common/RadioGroup/type";
import { TextFieldProps } from "../common/TextField/type";
import { SelectFieldProps } from "../common/Select/type";
import Select from "../common/Select";

export const RadioGroupField = makeField<RadioGroupProps>(RadioGroup);
export const TextField = makeField<TextFieldProps>(TextFieldMui);
export const SelectField = makeField<SelectFieldProps>(Select);
