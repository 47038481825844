import styled from "styled-components";

export const Container = styled.div<{
  backgroundColor?: string;
}>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "rgba(0, 0, 0, 0)"};
  position: relative;
  box-sizing: border-box;
  width: 100vw;
  z-index: 10;
  display: flex;
  padding-top: 44px;
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
  height: 30px;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 7px;
`;

export const ContainerIcon = styled.div`
  padding-right: 18px;
  padding-top: 5px;
  position: absolute;
  right: 0;

  & .MuiIconButton-root {
    width: 20px;
    height: 20px;
  }
`;
