import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background-color: white;
  border-radius: 16px 16px 0px 0px;
  flex-direction: column;
  margin-top: 24px;
  height: 100%;
  overflow: auto;
`;

export const ContainerBackground = styled.div`
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
  height: 100%;
  min-height: 85vh;
  /* margin-bottom: 88px; */
  /* align-items: center; */
`;

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  padding-top: 8px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
`;

export const ContainerSkeletonCard = styled.div`
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: column;
`;
export const ContainerSkeleton = styled.div`
  display: flex;
  margin: 16px 0 16px 0;
`;
