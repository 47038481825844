import { Box } from "@mui/system";
import Header from "../../Header";
import { MainLayoutProp } from "./type";
import { useEffect, useState } from "react";
import { useApplication } from "../../../App.context";

const MainLayout = (props: MainLayoutProp) => {
  const { component: Component, closeIcon = true, color } = props;
  const { appTheme } = useApplication();

  return (
    <Box
      sx={{
        background: color ? color : appTheme?.default.background.color, // get color from theme context
        height: "100%",
      }}
    >
      <Header closeIcon={closeIcon} />
      <Component />
    </Box>
  );
};

export default MainLayout;
