import Text from "../common/Text";
import { Container, ContainerContent, ContainerIcon } from "./styled";
import { IconButton } from "@mui/material";
import { HeaderProp } from "./type";
import { useCallback } from "react";
import {
  closeWebView,
  errorHeaderTitle,
  getFieldLang,
  getLang,
  getQuestions,
  useRouter,
} from "../../utils/helper";
import Skeleton from "@mui/material/Skeleton";
import { useApplication } from "../../App.context";
import CloseIcon from "../common/CloseIcon";
import * as paths from "../../constants/paths";
declare global {
  interface Window {
    webkit: {
      messageHandlers: {
        observer: {
          postMessage: (data: any) => void;
        };
      };
    };
    JSBridge: () => void;
  }
}

const Header = (prop: HeaderProp) => {
  const { closeIcon: closeIconProp = true } = prop;
  const { appTheme } = useApplication();
  const data = getQuestions();
  const lang = getLang().toLowerCase();
  const onClickIcon = useCallback(() => {
    closeWebView();
  }, []);
  const { pathname } = useRouter();
  const isErrorPage = pathname === paths.error();
  return (
    <Container>
      <ContainerContent>
        {data?.dynamic_label || isErrorPage ? (
          <Text
            type="Bold"
            lineHeight="30px"
            size="20px"
            talk={
              isErrorPage
                ? errorHeaderTitle()
                : getFieldLang(data.dynamic_label, "header_text", lang)
            }
          >
            {isErrorPage
              ? errorHeaderTitle()
              : getFieldLang(data.dynamic_label, "header_text", lang)}
          </Text>
        ) : (
          <Skeleton
            variant="rounded"
            width="100%"
            animation="wave"
            sx={{
              height: "20px",
              bgcolor: appTheme?.questionnaire.skeleton_color,
              borderRadius: "6px",
              maxWidth: "149px",
            }}
          />
        )}

        {closeIconProp && (
          <ContainerIcon onClick={onClickIcon} role="button">
            <IconButton size="small" aria-label={lang === "th" ?  "ปิด" : lang === "en" ? "close": "ปิด"}>
              <CloseIcon />
            </IconButton>
          </ContainerIcon>
        )}
      </ContainerContent>
    </Container>
  );
};
export default Header;
