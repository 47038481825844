import Content from "./Components/Content";
import { Container } from "./style";
import { useRouter } from "../../utils/helper";
import { useEffect } from "react";

const Introduction = () => {
  const { state } = useRouter()?.location;
  const {
    title,
    latestSubmissionDatetimeLabel,
    latestSubmissionDatetime,
    testResultLabel,
    testResult,
    subTitle,
    retryButton,
    submissionLimit,
    totalSubmission,
    anableButton,
  } = state || {};

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <Container>
      <Content
        title={title}
        latestSubmissionDatetimeLabel={latestSubmissionDatetimeLabel}
        latestSubmissionDatetime={latestSubmissionDatetime}
        testResultLabel={testResultLabel}
        testResult={testResult}
        subTitle={subTitle}
        retryButton={retryButton}
        submissionLimit={submissionLimit}
        totalSubmission={totalSubmission}
        anableButton={anableButton}
      />
    </Container>
  );
};

export default Introduction;
