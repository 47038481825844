import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import dayjs from "dayjs";
import humps from "humps";
import { ContentType, deepLoop } from "./tools";
import qs from "qs";
import {
  getToken,
  getTraceParent,
  getXSurveyDest,
  getLang,
  getCaseErrorMassage,
} from "../helper";

const BASE_TODO_API_HOST = `${process.env.REACT_APP_GT_HOST}`;
const BASE_TODO_API_HOST_PREFIX = `${process.env.REACT_APP_GT_PREFIX}`;

const createClient = () => {
  const ax = axios.create();
  ax.interceptors.request.use((request: AxiosRequestConfig) => {
    request.url = `${BASE_TODO_API_HOST}/${getXSurveyDest()}/${BASE_TODO_API_HOST_PREFIX}/${
      request.url
    }`;
    const headers = request.headers || {};

    headers.common["Authorization"] = `Bearer ${getToken()}`;
    headers.common["Traceparent"] = getTraceParent();
    headers.common["x-survey-dest"] = getXSurveyDest();
    headers.common["Accept-Language"] = getLang();

    if (headers["Content-Type"] === ContentType.FORMDATA) {
      if (request.data) {
        const formData = new FormData();

        request.data = formData;
      }
    } else if (headers["Content-Type"] === ContentType.XFORM) {
      request.params = modifyRequestData(request.params);
      if (request.data) {
        request.data = modifyRequestData(request.data);
        request.data = qs.stringify(request.data);
      }
    } else if (headers["Content-Type"] === ContentType.JSON) {
      request.params = modifyRequestData(request.params);
      if (request.data) {
        request.data = deepLoop(request.data, modifyRequestData);
        request.data = humps.decamelizeKeys(request.data);
      }
    }

    return request;
  });
  ax.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      const { data } = response;
      if (data && data.code !== undefined && data.code !== 0) {
        return Promise.reject({ response });
      } else {
        return response;
      }
    },
    (error: any) => {
      const { response, config } = error;
      return Promise.reject({ ...response, url: config.url });
    }
  );
  return ax;
};

const createClientPublic = () => {
  const ax = axios.create();
  ax.interceptors.request.use((request: AxiosRequestConfig) => {
    request.url = `${request.url}`;
    const headers = request.headers || {};
    if (headers["Content-Type"] === ContentType.FORMDATA) {
      if (request.data) {
        const formData = new FormData();
        request.data = formData;
      }
    } else if (headers["Content-Type"] === ContentType.XFORM) {
      request.params = modifyRequestData(request.params);
      if (request.data) {
        request.data = modifyRequestData(request.data);
        request.data = qs.stringify(request.data);
      }
    } else if (headers["Content-Type"] === ContentType.JSON) {
      request.params = modifyRequestData(request.params);
      if (request.data) {
        request.data = deepLoop(request.data, modifyRequestData);
        request.data = humps.decamelizeKeys(request.data);
      }
    }

    return request;
  });
  ax.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      const { data } = response;
      if (data && data.code !== undefined && data.code !== 0) {
        return Promise.reject({ response });
      } else {
        return response;
      }
    },
    (error: AxiosError<any>) => {
      const { response, config } = error;
      return Promise.reject({ ...response, url: config.url });
    }
  );
  return ax;
};

const modifyRequestData = (data: any) => {
  if (dayjs.isDayjs(data)) {
    return data.format();
  }
  return data;
};

export const gtClient = createClient();
export const gtPublic = createClientPublic();

export const gtApiWrapper = async (method: Promise<AxiosResponse>) => {
  try {
    const res = await method;
    return Promise.resolve(res);
  } catch (e: any) {
    let accData = {};
    const { message, code, data, url } = (e || {}) as any;
    console.log("error", e);
    accData["status"] = e?.status ? e?.status : 500;
    accData["code"] = code;
    accData["message"] = getCaseErrorMassage(url, code, message);

    if (data && typeof data === "object") {
      accData = { ...data };
    }

    return Promise.reject(accData || message || code || e);
  }
};
