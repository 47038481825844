import Card from "../common/Card";
import Text from "../common/Text";
import { QuestionCardType } from "./type";
import Radio from "@mui/material/Radio";

import FormControlLabel from "@mui/material/FormControlLabel";
import { RadioGroupField } from "../fields";
import { Divider } from "@mui/material";
import { Container } from "./style";
import { ReactComponent as RadioIcon } from "../../constants/icons/ic_radio.svg";
import { ReactComponent as RadioSelectedIcon } from "../../constants/icons/ic_radio_selected.svg";
import { useApplication } from "../../App.context";
import { getFieldLang, getLang } from "../../utils/helper";
import { DangerousDiv } from "../../pages/Introduction/Components/Content/style";

const QuestionCard = (props: QuestionCardType) => {
  const { id, index, type, options, divider = true } = props;
  const { appTheme } = useApplication();
  const lang = getLang().toLowerCase();
  return (
    <Container>
      <Card>
        <Text
          type="Bold"
          size="16px"
          lineHeight="24px"
          talk={`${index}. ${getFieldLang(props, "title_voiceover", lang)}`}
        >
          {`${index}. ${getFieldLang(props, "title", lang)}`}
        </Text>

        {type === "radio" && (
          <RadioGroupField
            required={true}
            aria-label={getFieldLang(props, "title", lang)}
            name={`questions.question${id.toString()}`}
            row
          >
            {options?.map((op: Option) => {
              const { id, value } = op;
              const answerValue = {
                id,
                value,
              };
              return (
                    <FormControlLabel
                      key={`${id}-${getFieldLang(op, "label", lang)}`}
                      aria-label={getFieldLang(op, "label_voiceover", lang)}
                      label={
                        <DangerousDiv
                          dangerouslySetInnerHTML={{
                            __html: getFieldLang(op, "label", lang),
                          }}
                        ></DangerousDiv>
                      }
                      value={JSON.stringify(answerValue)}
                      control={
                        <Radio
                          icon={
                            <RadioIcon
                              width={24}
                              height={24}
                              color={appTheme?.questionnaire.option.color}
                            />
                          }
                          checkedIcon={
                            <RadioSelectedIcon
                              width={24}
                              height={24}
                              color={appTheme?.questionnaire.option.color}
                              stroke={appTheme?.questionnaire.option.color}
                            />
                          }
                          style={{ backgroundColor: "transparent" }}
                        />
                      }
                    />
              );
            })}
          </RadioGroupField>
        )}
      </Card>
      {divider && <Divider variant="middle" aria-hidden="true" />}
    </Container>
  );
};

export default QuestionCard;
