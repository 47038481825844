import React from "react";
import FlexCard from "../../../../../components/common/FlexCard";
import {
  EmptySpace,
  StyledImage,
  StyledLayoutText,
  StyledLayoutTextContent,
  StyledLayoutTextResult,
  StyledSkeletonResult,
} from "../style";
import { Container, Skeleton } from "@mui/material";
import { useApplication } from "../../../../../App.context";
import ImagePlaceholder from "../../../../../components/common/Placeholder/image";
import ReactDOMServer from "react-dom/server";
import { SubContainer } from "../../../style";
import { GRAY_F9FAFB } from "../../../../../constants/colors";
import Footer from "../../Footer";

const SkeletonUI = () => {
  const { appTheme } = useApplication();

  const fallbackImage = !!appTheme?.questionnaire.result_image_url;

  const imagePlaceholder = ReactDOMServer.renderToString(
    <ImagePlaceholder
      bg={appTheme?.questionnaire.placeholder_color_1}
      fg={appTheme?.questionnaire.placeholder_color_2}
    />
  );

  const skeletonQuestions: any[] = [];
  // const isSkeleton = false;
  for (let index = 0; index < 3; index++) {
    skeletonQuestions.push(
      <FlexCard marginY={1} bgColor={appTheme?.questionnaire.skeleton_card_color}>
        <StyledLayoutTextContent>
          <StyledLayoutText style={{ gap: "0.5rem", marginBottom: "0.75rem" }}>
            <Skeleton
              variant="rounded"
              width="100%"
              height="16px"
              animation="wave"
              sx={{
                bgcolor: appTheme?.questionnaire.skeleton_color,
                borderRadius: "0.375rem",
                alignSelf: "start",
              }}
            />
            <Skeleton
              variant="rounded"
              width="163px"
              height="16px"
              animation="wave"
              sx={{
                fontSize: "20px",
                bgcolor: appTheme?.questionnaire.skeleton_color,
                borderRadius: "0.375rem",
                alignSelf: "start",
              }}
            />
          </StyledLayoutText>
        </StyledLayoutTextContent>
        <StyledLayoutTextContent>
          <StyledLayoutTextResult style={{ gap: "0.75rem" }}>
            <Skeleton
              variant="rounded"
              width="120px"
              height="16px"
              animation="wave"
              sx={{
                fontSize: "20px",
                bgcolor: appTheme?.questionnaire.skeleton_color,
                borderRadius: "0.375rem",
              }}
            />
            <Skeleton
              variant="rounded"
              width="48px"
              height="16px"
              animation="wave"
              sx={{
                fontSize: "20px",
                bgcolor: appTheme?.questionnaire.skeleton_color,
                borderRadius: "0.375rem",
              }}
            />
          </StyledLayoutTextResult>

          <StyledLayoutTextResult>
            <Skeleton
              variant="rounded"
              width="120px"
              height="16px"
              animation="wave"
              sx={{
                fontSize: "20px",
                bgcolor: appTheme?.questionnaire.skeleton_color,
                borderRadius: "0.375rem",
              }}
            />
            <StyledSkeletonResult>
              <Skeleton
                variant="rounded"
                width="64px"
                height="16px"
                animation="wave"
                sx={{
                  fontSize: "20px",
                  bgcolor: appTheme?.questionnaire.skeleton_color,
                  borderRadius: "0.375rem",
                }}
              />
            </StyledSkeletonResult>
          </StyledLayoutTextResult>
        </StyledLayoutTextContent>
      </FlexCard>
    );
  }
  return (
    <SubContainer backgroundColor={appTheme?.default.background.color}>
      <FlexCard
        bgColor={GRAY_F9FAFB}
        topImage={
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{
              bgcolor: appTheme?.questionnaire.skeleton_color,
              borderRadius: ".5rem",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <StyledImage
              fallbackImage={fallbackImage}
              data={
                appTheme?.questionnaire.result_image_url
                  ? appTheme?.questionnaire.result_image_url
                  :""
              }
              draggable="false"
              role="img"
              aria-label="Skeleton Image"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.data = `data:image/svg+xml,${encodeURIComponent(
                  imagePlaceholder
                )}`;

              }}
            />
          </Skeleton>
        }
        paddingTop={"62px"}
      >
        <StyledLayoutText style={{ gap: "0.5rem", marginBottom: '7px' }}>
          <Skeleton
            variant="rounded"
            width="200px"
            height="20px"
            animation="wave"
            sx={{
              fontSize: "30px",
              bgcolor: appTheme?.questionnaire.skeleton_color,
              borderRadius: "0.375rem",
            }}
          />
          <Skeleton
            variant="rounded"
            width="149px"
            height="20px"
            animation="wave"
            sx={{
              fontSize: "30px",
              bgcolor: appTheme?.questionnaire.skeleton_color,
              borderRadius: "0.375rem",
            }}
          />
        </StyledLayoutText>

        {skeletonQuestions}

        <div style={{ marginTop: "32px" }}>
          <Footer isLoading={true} />
        </div>
        <EmptySpace></EmptySpace>
      </FlexCard>
    </SubContainer>
  );
};

export default SkeletonUI;
