import styled from "styled-components";
import { Box } from "@mui/material";
import { WHITE_FFFFFF } from "../../../constants/colors";

export const StyledCard = styled(Box)<{
  topImage?: React.ReactNode;
  bgColor?: string;
  fullHeight?: boolean;
  marginX?: string | number;
  marginY?: string | number;
  paddingX?: string | number;
  paddingY?: string | number;
  paddingTop?: string | number;
}>(
  ({
    topImage,
    bgColor,
    fullHeight,
    marginX = 0,
    marginY = 0,
    paddingX = 16,
    paddingY = 16,
    paddingTop,
  }) => ({
    display: "flex",
    flexDirection: "column",
    backgroundColor: bgColor ?? `${WHITE_FFFFFF}`,
    position: "relative",
    boxSizing: "border-box",
    zIndex: 1,
    top: topImage ? -30 : 0,
    borderRadius: 16,
    paddingTop: topImage ? (paddingTop || 40) : paddingY,
    paddingBottom: paddingY,
    paddingLeft: paddingX,
    paddingRight: paddingX,
    marginLeft: marginX,
    marginRight: marginX,
    marginTop: marginY,
    marginBottom: marginY,
    ...(fullHeight && { height: "100vh" }),
  })
);

export const StyledImageTop = styled(Box)`
  position: relative;
  z-index: 2;
`;
