import { Route, Routes as RoutesLib } from "react-router-dom";
import MainLayout from "../components/Layout/MainLayout";
import ImageLayout from "../components/Layout/ImageLayout";
import Finish from "../pages/Finish";
import Home from "../pages/Home";
import Error from "../pages/Error";
import Introduction from "../pages/Introduction";
import Success from "../pages/Success";
import * as paths from "../constants/paths";
import Survey from "../pages/Survey";
import { GRAY_F9FAFB } from "../constants/colors";

const Routes = () => {
  return (
    <RoutesLib>
      <Route
        element={<Home />}
        path={paths.home()}
        errorElement={paths.error()}
      />
      <Route
        element={<MainLayout component={Finish} />}
        path={paths.finish()}
      />
      <Route
        element={<MainLayout component={Survey} />}
        path={paths.survey()}
      />
      <Route
        element={<ImageLayout component={Introduction} />}
        path={paths.introduction()}
      />
      <Route
        element={<ImageLayout component={Success} />}
        path={paths.success()}
      />
      <Route
        element={<MainLayout component={Error} color={GRAY_F9FAFB} />}
        path={paths.error()}
      />
    </RoutesLib>
  );
};

export default Routes;
