import Text from "../../../../components/common/Text";
import { GRAY_565656 } from "../../../../constants/colors";
import {
  Container,
  ContainerImage,
  EmptySpace,
  StyledImage,
  StyledLayoutText,
} from "./style";
import ImageBOTGuide from "../../../../constants/images/image_bot_guide.jpeg";

const Content = () => {
  return (
    <Container>
      <StyledLayoutText>
        <Text
          type="Bold"
          size="20px"
          lineHeight="30px"
          talk="ขอบคุณที่ร่วมทำแบบทดสอบ"
        >
          ขอบคุณที่ร่วมทำแบบทดสอบ
        </Text>
        <Text
          weight={400}
          size={"16px"}
          color={GRAY_565656}
          talk={
            "อ่านคำแนะนำเพิ่มเติมเกี่ยวกับการทำธุรกรรมอย่างปลอดภัยได้ที่ด้านล่าง"
          }
        >
          อ่านคำแนะนำเพิ่มเติมเกี่ยวกับการทำธุรกรรมอย่างปลอดภัยได้ที่ด้านล่าง
        </Text>
      </StyledLayoutText>
      <ContainerImage>
        <StyledImage
          src={ImageBOTGuide}
          alt="คำแนะนำเพิ่มเติม
          วิธีลดความเสี่ยงจากกลโกงมิจฉาชีพ
          ไม่ควรบันทึกรหัสผ่านไว้ในโทรศัพท์
          ไม่ควรติดตั้งแอปหรือกดลิงก์ที่ไม่ทราบที่มา
          ควรอัปเดตซอร์ฟแวร์ของโทรศัพท์มือถือเป็นประจำ
          ไม่กรอกข้อมูลส่วนตัวลงช่องทางออนไลน์
          ตรวจชื่อเว็บไซต์ให้มั่นใจก่อนทำธุรกรรม
          หลีกเลี่ยงการใช้ไวไฟที่ไม่รู้จัก
          เปิดใช้แจ้งเตือนเงินเข้าออกผ่าน ไลน์ กรุงไทย คอนเนค"
        />
      </ContainerImage>
      <EmptySpace></EmptySpace>
    </Container>
  );
};

export default Content;
