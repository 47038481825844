import { Container } from "./style";
import Content from "./Component/Content";
import Footer from "./Component/Footer";
import { useEffect } from "react";

const Error = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <Container>
      <Content />
      <Footer />
    </Container>
  );
};

export default Error;
