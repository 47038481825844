import { FC } from "react";
import { Container, ContainerIconSkeleton } from "./styled";
import { HeaderSpaceProp } from "./type";
import { ContainerIcon } from "./styled";
import { IconButton } from "@mui/material";
import { useCallback } from "react";
import { closeWebView, getLang } from "../../utils/helper";
import Skeleton from "@mui/material/Skeleton";
import CloseIcon from "../common/CloseIcon";
import { useApplication } from "../../App.context";
declare global {
  interface Window {
    webkit: {
      messageHandlers: {
        observer: {
          postMessage: (data: any) => void;
        };
      };
    };
    JSBridge: () => void;
  }
}
const HeaderSpace: FC<HeaderSpaceProp> = ({ color, LoadingColor }) => {
  const { appTheme } = useApplication();
  const isSkeleton = false;
  const lang = getLang().toLowerCase();
  const onClickIcon = useCallback(() => {
    closeWebView();
  }, []);
  return (
    <Container color={color} >
      {isSkeleton ? (
        <ContainerIconSkeleton>
          <Skeleton
            variant="circular"
            animation="wave"
            width={40}
            height={40}
            sx={{ bgcolor: appTheme?.questionnaire.skeleton_color }}
          />
        </ContainerIconSkeleton>
      ) : (
        <ContainerIcon onClick={onClickIcon} role="button">
          <IconButton size="small" aria-label={lang === "th" ?  "ปิด" : lang === "en" ? "close": "ปิด"}>
            <CloseIcon LoadingColor={LoadingColor} />
          </IconButton>
        </ContainerIcon>
      )}
    </Container>
  );
};
export default HeaderSpace;
