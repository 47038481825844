import styled from "styled-components";
import { BLUE_E1F1FF } from "../../constants/colors";

export const Container = styled.div<{
  backgroundColor?: string;
}>`
background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : `${BLUE_E1F1FF}`};

  height: auto;
  position: relative;
  width: 100vw;
  overflow: auto;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
`;

export const SubContainer = styled.div<{
  backgroundColor?: string;
}>`
background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : `${BLUE_E1F1FF}`};

  height: auto;
  position: relative;
  width: 100vw;
  overflow: auto;
  padding-left: 0;
  padding-right: 0;
  box-sizing: border-box;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
`;
