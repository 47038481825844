import { useCallback } from "react";
import FooterComponent, {
  ButtonFooter,
} from "../../../../components/common/Footer";
import Text from "../../../../components/common/Text";
import { closeWebView } from "../../../../utils/helper";
import { useApplication } from "../../../../App.context";

declare global {
  interface Window {
    webkit: {
      messageHandlers: {
        observer: {
          postMessage: (data: any) => void;
        };
      };
    };
    JSBridge: () => void;
  }
}

const Footer = () => {
  const onClickButton = useCallback(() => {
    closeWebView();
  }, []);
  const { appTheme } = useApplication();
  return (
    <FooterComponent>
      <ButtonFooter
        onClick={onClickButton}
        talk="กลับหน้าหลัก"
        backgroundColor={appTheme?.default.button.background_color}
      >
        <Text color="white" type="Bold" size="16px" lineHeight="24px">
          กลับหน้าหลัก
        </Text>
      </ButtonFooter>
    </FooterComponent>
  );
};

export default Footer;
