//SOLID COLORS//
export const BLACK_212121 = "#212121";
export const BLACK_000000 = "#000000";
export const BLUE_004C7C = "#004C7C";
export const BLUE_025BB7 = "#025BB7";
export const BLUE_00A6E6 = "#00A6E6";
export const GRAY_F8F8F8 = "#F8F8F8";
export const GRAY_838383 = "#838383";
export const GRAY_565656 = "#565656";
export const GRAY_E0E0E0 = "#E0E0E0";
export const GRAY_EAF0F5 = "#EAF0F5";
export const GRAY_F2F2F2 = "#F2F2F2";
export const GRAY_F2F4F7 = "#F2F4F7";
export const GRAY_CACACA = "#CACACA";
export const GRAY_F9FAFB = "#F9FAFB";
export const GRAY_8A9FAB = "#8A9FAB";
export const GRAY_5A6E7B = "#5A6E7B";
export const GRAY_BCCCD6 = "#BCCCD6";
export const GREEN_70B412 = "#70B412";
export const GREEN_63BE00 = "#63BE00";
export const RED_DB0000 = "#DB0000";
export const RED_FFF0F0 = "#FFF0F0";
export const WHITE_FFFFFF = "#FFFFFF";
export const BLUE_E1F1FF = "#E1F1FF";
export const PRIMARY_007BC4 = "#007BC4";
export const BLUE_C7E3F4 = "#C7E3F4";
export const BLUE_007BC4 = "#007BC4"

//GRADIENT COLORS//
export const GRADIENT_PRIMARY =
  "linear-gradient(62.12deg, #007BC4 0%, #04BAEE 100%)";
