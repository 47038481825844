import { useCallback, ChangeEvent } from "react";
import { FormControl } from "@mui/material";
import { TextFieldProps } from "./type";
import TextFieldMui from "@mui/material/TextField";

const TextField = (props: TextFieldProps) => {
  const {
    value,
    onChange,
    name = "text-field",
    rows = 1,
    ...resetProp
  } = props;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      onChange?.(value);
    },
    [onChange]
  );

  return (
    <FormControl component="fieldset">
      <TextFieldMui
        name={name}
        value={value}
        onChange={handleChange}
        rows={rows}
        {...resetProp}
      />
    </FormControl>
  );
};

export default TextField;
