import { FC, useCallback } from "react";
import { ButtonFooter } from "../../../../components/common/Footer";
import Text from "../../../../components/common/Text";
import { clearSession, closeWebView, getFieldLang, getLang, getQuestions } from "../../../../utils/helper";
import Skeleton from "@mui/material/Skeleton";
import { useApplication } from "../../../../App.context";
declare global {
  interface Window {
    webkit: {
      messageHandlers: {
        observer: {
          postMessage: (data: any) => void;
        };
      };
    };
    JSBridge: () => void;
  }
}

type SuccessFooterProps = {
  isLoading: boolean;
};

const Footer: FC<SuccessFooterProps> = ({ isLoading }) => {

  const questionList = getQuestions();
  const lang = getLang().toLowerCase();

  const onClickButton = useCallback(() => {
    clearSession();
    closeWebView();
  }, []);
  // const isSkeleton = false;
  const { appTheme } = useApplication();
  return (
    <>
      {isLoading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          width={"100%"}
          height={48}
          sx={{
            maxWidth: "358px",
            bgcolor: appTheme?.questionnaire.skeleton_color,
            borderRadius: "0.75rem",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      ) : (
        <ButtonFooter
          onClick={onClickButton}
          talk={getFieldLang(questionList.dynamic_label.result,"complete_text",lang)}
          backgroundColor={appTheme?.default.button.background_color}
        >
          <Text color="white" type="Bold" size="16px" lineHeight="24px">
            {getFieldLang(questionList.dynamic_label.result,"complete_text",lang)}
          </Text>
        </ButtonFooter>
      )}
    </>
  );
};

export default Footer;
