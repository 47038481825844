import React, { useCallback } from "react";
import FooterComponent, {
  ButtonFooter,
} from "../../../../components/common/Footer";
import Text from "../../../../components/common/Text";
import {
  closeWebView,
  getFieldLang,
  getLang,
  getQuestions,
} from "../../../../utils/helper";
import { useApplication } from "../../../../App.context";

declare global {
  interface Window {
    webkit: {
      messageHandlers: {
        observer: {
          postMessage: (data: any) => void;
        };
      };
    };
    JSBridge: () => void;
  }
}

const Footer = () => {
  const onClickButton = useCallback(() => {
    closeWebView();
  }, []);
  const { appTheme } = useApplication();
  const lang = getLang().toLowerCase();
  const data = getQuestions();

  return (
    <FooterComponent backgroundColor="transparent">
      <ButtonFooter
        onClick={onClickButton}
        backgroundColor={appTheme?.default.button.background_color}
      >
        <Text color="white" type="Bold" size="16px" lineHeight="24px">
          {data?.dynamic_label.accept_th && data?.dynamic_label.accept_en
            ? getFieldLang(data?.dynamic_label, "accept", lang)
            : lang === "en"
            ? "OK"
            : "ตกลง"}
        </Text>
      </ButtonFooter>
    </FooterComponent>
  );
};

export default Footer;
