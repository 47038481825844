import FooterComponent, {
  ButtonFooter,
} from "../../../../components/common/Footer";
import Text from "../../../../components/common/Text";
import { FormSpy } from "react-final-form";
import { FormValues } from "../../type";
import { GRAY_8A9FAB } from "../../../../constants/colors";
import { useCallback, useState } from "react";
import { FooterProp } from "./types";
import Skeleton from "@mui/material/Skeleton";
import { useApplication } from "../../../../App.context";
import {
  getFieldLang,
  getLang,
  getQuestions,
  getXSurveyDest,
} from "../../../../utils/helper";

const Footer = (props: FooterProp) => {
  const { onClickButton: onClickButtonProp } = props;
  const [isButonDisabled, setIsButonDisabled] = useState(true);
  const data = getQuestions();
  const lang = getLang().toLowerCase();
  const onClickButton = useCallback(() => {
    setIsButonDisabled(false);
    onClickButtonProp?.();
  }, [setIsButonDisabled, onClickButtonProp]);

  const { appTheme } = useApplication();

  return (
    <FormSpy<FormValues>
      subscription={{
        valid: true,
      }}
    >
      {({ valid }) => {
        return (
          <FooterComponent backgroundColor="white">
            {props.isSkeleton ? (
              <Skeleton
                variant="rounded"
                animation="wave"
                width="100%"
                height={48}
                style={{
                  borderRadius: "0.75rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxWidth: "343px",
                }}
                sx={{ bgcolor: appTheme?.questionnaire.skeleton_color }}
              />
            ) : (
              <ButtonFooter
                type="submit"
                isValid={valid && isButonDisabled}
                onClick={onClickButton}
                talk={getFieldLang(data.dynamic_label, "submit_text", lang)}
                backgroundColor={appTheme?.default.button.background_color}
              >
                <Text
                  color={
                    valid
                      ? "white"
                      : getXSurveyDest() === "ibank"
                      ? "white"
                      : GRAY_8A9FAB
                  }
                  type="Bold"
                  size="16px"
                  lineHeight="24px"
                >
                  {getFieldLang(data.dynamic_label, "submit_text", lang)}
                </Text>
              </ButtonFooter>
            )}
          </FooterComponent>
        );
      }}
    </FormSpy>
  );
};

export default Footer;
