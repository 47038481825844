import { ReactNode, useCallback, useMemo } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { QueryClient, QueryClientProvider } from "react-query";
import * as paths from "../constants/paths";
import { ERROR } from "../services/error/error-query";
import sha256 from "crypto-js/sha256";
import {
  GENERATE_TOKEN_IBANK,
  GENERATE_TOKEN_NEXT,
  GENERATE_TOKEN_PT,
  QUESTIONAIRE_INQUIRY,
} from "../services/home/home-query";
import { QUESTIONAIRE_ANSWER_INQUIRY } from "../services/success/success-query";

export const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  return useMemo(() => {
    return {
      push: navigate,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search.slice(1)),
        ...params,
      },
      location,
      navigate,
    };
  }, [location, navigate, params]);
};

export const clearSession = () => {
  sessionStorage.clear();
};

export const setParamURL = (result: any) => {
  sessionStorage.setItem("PARAMURL", JSON.stringify(result));
};

const jsonParseSessionItem = (sessionKey: string) => {
  try {
    return JSON.parse(sessionStorage.getItem(sessionKey) || "");
  } catch {
    return undefined;
  }
};

export const getParamURL = () => {
  return jsonParseSessionItem("PARAMURL");
};

export const setLatestSubmissionInfo = (result: any) => {
  sessionStorage.setItem("LATESTSUBMISSIONINFO", JSON.stringify(result));
};

export const getLatestSubmissionInfo = () => {
  return jsonParseSessionItem("LATESTSUBMISSIONINFO");
};

export const setResponse = (response: any) => {
  sessionStorage.setItem("RESPONSE", JSON.stringify(response));
};

export const getResponse = () => {
  return jsonParseSessionItem("RESPONSE");
};

export const setQuestions = (result: any) => {
  sessionStorage.setItem("QUESTIONS", JSON.stringify(result));
};

export const getQuestions = () => {
  return jsonParseSessionItem("QUESTIONS");
};

export const setResult = (result: SubmitQuestionBOTType) => {
  sessionStorage.setItem("QUESTION_RESULT", JSON.stringify(result));
};

export const getResult = () => {
  return jsonParseSessionItem("QUESTION_RESULT");
};

export const resetToken = () => {
  sessionStorage.removeItem("AUTH_TOKEN");
};

export const setToken = (token: string) => {
  sessionStorage.setItem("AUTH_TOKEN", token);
};

export const getToken = () => {
  return sessionStorage.getItem("AUTH_TOKEN");
};

export const getTraceId = () => {
  return sessionStorage.getItem("TRACE_ID");
};

export const setTraceId = (traceId: string) => {
  sessionStorage.setItem("TRACE_ID", traceId);
};

export const genTraceId = () => {
  const traceId = sha256(Math.random().toString()).toString().substring(0, 32);
  setTraceId(traceId);

  return traceId;
};

export const closeWebView = () => {
  const command = "closeWebview";

  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf("android") > -1;

  if (isAndroid) {
    // @ts-ignore
    window.JSBridge.closeWebview("");
  } else if (window.webkit) {
    window.webkit.messageHandlers.observer.postMessage({
      command,
    });
  }
};

export const getTraceParent = () => {
  const version = "00";

  let traceId = getTraceId();

  if (!traceId) {
    traceId = genTraceId();
  }

  const parentId = sha256(Math.random().toString()).toString().substring(0, 16); // 16 hex string

  const traceFlags = "01";

  return `${version}-${traceId}-${parentId}-${traceFlags}`;
};

export const getXSurveyDest = () => {
  const channel = getParamURL()?.channel.toString().toUpperCase();

  switch (channel) {
    case "NEXT":
      return "ktb";
    case "PAOTANG":
      return "ktb";
    default:
      return "ibank";
  }
};

export const getLang = () => {
  const lang = getParamURL()?.lang;
  return lang || "th";
};

type CustomQueryClientProviderProp = {
  children?: ReactNode;
};
const client = new QueryClient();
export const CustomQueryClientProvider = (
  props: CustomQueryClientProviderProp
) => {
  const { children } = props;
  const { navigate } = useRouter();

  const onErrorHandler = useCallback(
    (error: any) => {
      const { status, code, message, exp } = error;
      console.log("error", error);
      client.setQueryData([ERROR], {
        status,
        data: {
          code,
          message,
          exp,
        },
      });
      navigate(paths.error());
    },
    [navigate]
  );

  useMemo(() => {
    client.setDefaultOptions({
      queries: {
        cacheTime: 30 * 1000,
        staleTime: 30 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: 0,
        onError: onErrorHandler,
      },
      mutations: {
        retry: 0,
        onError: onErrorHandler,
      },
    });
  }, [onErrorHandler]);

  return (
    <QueryClientProvider client={client}>
      {children && children}
    </QueryClientProvider>
  );
};

export const getFieldLang = (data: any, field: string, lang: string) => {
  if (!data || !data[`${field}_${lang}`] || data === undefined)
    return "NO_DATA";
  return data[`${field}_${lang}`];
};

export const getCaseErrorMassage = (
  url: string,
  code: number,
  message: string
) => {
  if (url.includes("/web-survey-template-prd/questionnaires")) {
    return "cannot get questionnaires";
  } else if (url.includes("/web-survey-template-prd/themes")) {
    return "cannot get themes";
  } else if (url.includes(QUESTIONAIRE_INQUIRY)) {
    return "cannot get question inquiry";
  } else if (
    url.includes(GENERATE_TOKEN_IBANK) ||
    url.includes(GENERATE_TOKEN_PT) ||
    url.includes(GENERATE_TOKEN_NEXT)
  ) {
    return "cannot generate token";
  } else if (url.includes(QUESTIONAIRE_ANSWER_INQUIRY)) {
    return "cannot submit answer";
  }
};

export const errorHeaderTitle = () => {
  const lang = getParamURL().lang;
  switch (lang) {
    case "TH":
      return "แบบทดสอบความรู้";
    case "EN":
      return "Awareness Test";
    default:
      return "แบบทดสอบความรู้";
  }
};

export const formatDatetime = (date: string) => {
  const month = date.split(" ")[1];
  switch (month) {
    case "ม.ค.": {
      return date.replace("ม.ค.", "มกราคม");
    }
    case "ก.พ.": {
      return date.replace("ก.พ.", "กุมภาพันธ์");
    }
    case "มี.ค.": {
      return date.replace("มี.ค.", "มีนาคม");
    }
    case "เม.ย.": {
      return date.replace("เม.ย.", "เมษายน");
    }
    case "พ.ค.": {
      return date.replace("พ.ค.", "พฤษภาคม");
    }
    case "มิ.ย.": {
      return date.replace("มิ.ย.", "มิถุนายน");
    }
    case "ก.ค.": {
      return date.replace("ก.ค.", "กรกฎาคม");
    }
    case "ส.ค.": {
      return date.replace("ส.ค.", "สิงหาคม");
    }
    case "ก.ย.": {
      return date.replace("ก.ย.", "กันยายน");
    }
    case "ต.ค.": {
      return date.replace("ต.ค.", "ตุลาคม");
    }
    case "พ.ย.": {
      return date.replace("พ.ย.", "พฤศจิกายน");
    }
    case "ธ.ค.": {
      return date.replace("ธ.ค.", "ธันวาคม");
    }

    default:
      return date;
  }
};
