import styled, { keyframes } from "styled-components";

const loadingAnimation = keyframes`
  0%,
  100% {
    left: 0px;
    width: 10.67px;
    height: 10.67px;
    z-index: 0;
  }

  25% {
    height: 10.67px;
    width: 10.67px;
    z-index: 1;
    left: 10px;
  }

  50% {
    width: 10.67px;
    height: 10.67px;
    left: 24px;
  }

  75% {
    width: 10.67px;
    height: 10.67px;
    left: 13px;
  }
`;

export const Container = styled.div`
  position: relative;
  height: 100vh;
`;

export const HeaderContainer = styled.div`
  position: absolute;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const DotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px !important;
`;

export const DotLoading = styled.span`
  width: 40px;
  position: relative;

  &::before,
  &::after {
    position: absolute;
    content: "";
    background: #5a6e7b;
    width: 10.67px;
    height: 10.67px;
    border-radius: 50%;
    mix-blend-mode: multiply;
    animation: ${loadingAnimation} 1s linear infinite;
  }

  &::after {
    background: #bcccd6;
    animation-delay: 0.5s;
  }
`;
