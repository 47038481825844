import { useMutation, useQuery } from "react-query";
import { api } from "../../utils/api";
import { resetToken, useRouter } from "../../utils/helper";
import { SubmitQuestionBOTType } from "./bot-type";
import * as paths from "../../constants/paths";

export const BOT_AWARENESS = "survey/template/bot-awareness";
export const BOT_SUBMIT = "survey/orchrestrator/submit-answer";

export const useSubmitQuestionBOT = () => {
  const { navigate } = useRouter();

  return useMutation(
    async (params: SubmitQuestionBOTType) => {
      const { data } = await api.gt.post(BOT_SUBMIT, params);
      return data;
    },
    {
      onSuccess: () => {
        navigate(paths.success());
      },
    }
  );
};

export const mockTemplate = {
  code: 0,
  message: "success",
  data: {
    description_en:
      "Test whether you understand how to make secure mobile transactions.",
    description_th:
      "ทดสอบว่าคุณมีความเข้าใจในการทำธุรกรรมผ่านโทรศัพท์มือถืออย่างปลอดภัยแล้วหรือยัง",
    questions: [
      {
        description_en:
          "Passwords should not be saved on the phone. For ease of use?",
        description_th:
          "ไม่ควรบันทึกรหัสผ่านไว้ในโทรศัพท์ เพื่อความสะดวกในการใช้งานใช่หรือไม่",
        id: 1,
        option: [
          {
            id: 1,
            label: "ใช่",
            value: true,
          },
          {
            id: 2,
            label: "ไม่ใช่",
            value: false,
          },
        ],
        title_en:
          "Passwords should not be saved on the phone. For ease of use?",
        title_th:
          "ไม่ควรบันทึกรหัสผ่านไว้ในโทรศัพท์ เพื่อความสะดวกในการใช้งานใช่หรือไม่",
        type: "radio",
      },
      {
        description_en:
          "Shouldn't install the application be downloaded from a link sent by someone else?",
        description_th:
          "ไม่ควรลงแอปพลิเคชันจากลิงก์ที่ผู้อื่นส่งมาให้ใช่หรือไม่",
        id: 2,
        option: [
          {
            id: 1,
            label: "ใช่",
            value: true,
          },
          {
            id: 2,
            label: "ไม่ใช่",
            value: false,
          },
        ],
        title_en:
          "Shouldn't install the application be downloaded from a link sent by someone else?",
        title_th: "ไม่ควรลงแอปพลิเคชันจากลิงก์ที่ผู้อื่นส่งมาให้ใช่หรือไม่",
        type: "radio",
      },
      {
        description_en: "Should I update my mobile phone software regularly?",
        description_th:
          "ควรอัปเดตซอฟต์แวร์ของโทรศัพท์มือถือเป็นประจำใช่หรือไม่",
        id: 3,
        option: [
          {
            id: 1,
            label: "ใช่",
            value: true,
          },
          {
            id: 2,
            label: "ไม่ใช่",
            value: false,
          },
        ],
        title_en: "Should I update my mobile phone software regularly?",
        title_th: "ควรอัปเดตซอฟต์แวร์ของโทรศัพท์มือถือเป็นประจำใช่หรือไม่",
        type: "radio",
      },
    ],
    survey_group_id: 1,
    title_en: "BOT Awareness Test",
    title_th: "ทำธุรกรรมผ่านโทรศัพท์มือถืออย่างไรให้ปลอดภัย",
    survey_format: "Fixed_Random",
    selected_subgroups: [1, 2],
    start_date: "2023-10-01",
    end_date: "2025-12-31",
    repeat_duration: "180d",
  },
};
