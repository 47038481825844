import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  FC,
  useContext,
  useState,
} from "react";
import { useSessionStorage } from "./hooks/useSessionStorage";
import { botTheme } from "./mock/getBotTheme";
import { iBankTheme } from "./mock/getIBankTheme";
import { useRouter } from "./utils/helper";

type AppContextType = {
  appTheme?: any;
  isLoading: boolean;
  setAppTheme: Dispatch<SetStateAction<any>>;
  setisLoading: Dispatch<SetStateAction<any>>;
};

const ApplicationContext = createContext<AppContextType>({} as AppContextType);

type Props = {
  children: ReactNode;
};

const ApplicationProvider: FC<Props> = ({ children }) => {
  const { query } = useRouter();
  const { channel } = query;

  const [appTheme, setAppTheme] = useSessionStorage("APP_THEME", channel?.toString().toUpperCase() === 'IBANK' ? iBankTheme() : botTheme());
  const [isLoading, setisLoading] = useState(true);
  return (
    <ApplicationContext.Provider
      value={{
        appTheme,
        setAppTheme,
        isLoading,
        setisLoading,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationProvider;
export const useApplication = () => useContext(ApplicationContext);
