import HeaderSpace from "../../HeaderSpace";
import { BLUE_E1F1FF } from "../../../constants/colors";
import { ImageLayoutProp } from "./type";
import { useApplication } from "../../../App.context";
import { Box } from "@mui/system";

const ImageLayout = (props: ImageLayoutProp) => {
  const { component: Component } = props;
  const { appTheme } = useApplication();

  return (
    <Box
      sx={{
        background: appTheme?.default.background.color,
        height: "100vh",
      }}
    >
      <HeaderSpace />
      <Component />
    </Box>
  );
};

export default ImageLayout;
