import styled from "styled-components";

import { GRAY_EAF0F5 } from "../../../constants/colors";
import { Button } from "@mui/material";
import { getXSurveyDest } from "../../../utils/helper";

export const StyledButton = styled(Button)<{
  valid: string;
  backgroundColor?: string;
}>`
  background: ${({ valid, backgroundColor }) =>
    valid === "true"
      ? backgroundColor + "!important"
      : getXSurveyDest() === "ibank"
      ? "#E0E0E0 !important"
      : GRAY_EAF0F5 + "!important"};
  border-radius: 8px !important;
  width: 100%;
  height: 48px;
`;

export const StyledFooter = styled.div<{
  backgroundColor?: string;
  isRelative?: boolean;
}>`
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  padding-bottom: 40px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 0px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: ${({ isRelative }) => (isRelative ? `relative` : `fixed`)};
  width: 100vw;
  z-index: 1;
`;
