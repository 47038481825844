import {
  Container,
  ContentContainer,
  DotContainer,
  DotLoading,
  HeaderContainer,
} from "./style";

import Text from "../Text";
import { useRouter } from "../../../utils/helper";
import HeaderSpace from "../../HeaderSpace";
import { useEffect, useState } from "react";

const Loading = () => {
  const { query } = useRouter();
  const { lang } = query;
  const [language, setLanguage] = useState<any>("TH");
  useEffect(() => {
    if (lang) {
      setLanguage(lang);
    }
  }, [lang]);

  return (
    <Container>
      <HeaderContainer>
        <HeaderSpace LoadingColor="#BCCCD6"></HeaderSpace>
      </HeaderContainer>
      <ContentContainer>
        <DotContainer>
          <DotLoading />
        </DotContainer>
        <Text color="#5A6E7B">
          {language
            ? language === "EN"
              ? "Loading"
              : "กำลังโหลด"
            : "กำลังโหลด"}
        </Text>
      </ContentContainer>
    </Container>
  );
};

export default Loading;
