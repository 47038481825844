import { useMutation } from "react-query";
import { useRouter } from "../../utils/helper";
import { InquiryAnswerBody } from "./success-type";
import { api } from "../../utils/api";

export const QUESTIONAIRE_ANSWER_INQUIRY = `survey/questionnaire-inquiry/answers`;

const getAnswerList = async (url: string) => {
  const { data } = await api.public.get(url);
  return data;
};

export const useGetInquiryAnswer = () => {
  return useMutation(async (params: InquiryAnswerBody) => {
    const path = QUESTIONAIRE_ANSWER_INQUIRY;
    const { data } = await api.gt.post(path, params);
    const response: any = data?.data;
    if (data.code === 0 && response.answer_url) {
      return getAnswerList(response.answer_url);
    }
    throw new Error("error");
  });
};
