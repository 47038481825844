import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: transparent;
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 8px;
`;

export const ContainerTextDescription = styled.div`
  margin-top: 8px;
`;
