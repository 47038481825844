import styled from "styled-components";

export const Container = styled("div")<{ color?: string }>(({ color }) => ({
  backgroundColor: color ? color : "rgba(0, 0, 0, 0)",
  position: "relative",
  boxSizing: "border-box",
  width: "100vw",
  zIndex: 10,
  display: "flex",
  height: "92px",
}));

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
  justify-content: center;
`;

export const ContainerIcon = styled.div`
  padding-right: 18px;
  padding-top: 5px;
  position: absolute;
  right: 0;
  margin-top: 44px;

  & .MuiIconButton-root {
    width: 20px;
    height: 20px;
  }
`;
export const ContainerIconSkeleton = styled.div`
  padding-right: 0.5rem;
  position: fixed;
  right: 0;
  margin-top: 44px;
`;
