import { useEffect } from "react";
import { useApplication } from "../../App.context";
import Content from "./Components/Content";
import { Container } from "./style";

const Success = () => {
  const { appTheme } = useApplication();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <Container backgroundColor={appTheme?.default.background.color}>
      <Content />
    </Container>
  );
};

export default Success;
