import Text from "../../../../components/common/Text";
import { GRAY_5A6E7B } from "../../../../constants/colors";
import QuestionList from "./Components/QuestionList";
import { Container, ContainerTextDescription, ContainerTitle } from "./styled";
import Skeleton from "@mui/material/Skeleton";
import { getFieldLang, getLang, getQuestions } from "../../../../utils/helper";
import { useApplication } from "../../../../App.context";

const Content = ({ isLoading }) => {
  const { appTheme } = useApplication();
  const data = getQuestions();
  const skeletonQuestions: any[] = [];
  const lang = getLang().toLowerCase();
  for (let index = 0; index < 3; index++) {
    skeletonQuestions.push(
      <Skeleton
        variant="rounded"
        width={index === 2 ? "50%" : "100%"}
        animation="wave"
        sx={{
          height: "16px",
          bgcolor: appTheme?.questionnaire.skeleton_color,
          marginBottom: "8px",
        }}
        style={{
          borderRadius: "6px",
        }}
      />
    );
  }

  return (
    <Container>
      <ContainerTitle>
        {isLoading ? (
          skeletonQuestions
        ) : (
          <ContainerTitle>
            <Text
              type="Bold"
              size="16px"
              lineHeight="24px"
              talk={getFieldLang(data, "title_voiceover", lang)}
            >
              {getFieldLang(data, "title", lang)}
            </Text>

            <ContainerTextDescription>
              <Text
                weight={400}
                color={GRAY_5A6E7B}
                size="16px"
                lineHeight="24px"
              >
                {getFieldLang(data, "description", lang)}
              </Text>
            </ContainerTextDescription>
          </ContainerTitle>
        )}
      </ContainerTitle>
      <QuestionList key={"questionList"} isLoading={isLoading} />
    </Container>
  );
};

export default Content;
