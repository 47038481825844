import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: auto;
`;

export const StyledLayoutText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
`;

export const ContainerImage = styled.div`
  border-radius: 16px;
  padding-top: 24px;
  max-width: 375px;
  padding-bottom: 65px;
`;

export const StyledImage = styled.img<{ fallbackImage?: boolean }>`
  width: ${({ fallbackImage }) => (fallbackImage ? "240px" : "210px")};
  height: ${({ fallbackImage }) => (fallbackImage ? "180px" : "158px")};
  max-height: 180px;
  object-fit: fill;
  display: block;
  margin: 0 auto;
  transform: ${({ fallbackImage }) =>
    fallbackImage ? "translateY(13.2%)" : ""};
`;
export const EmptySpace = styled.div`
  height: 48px;
  display: flex;
  flex-direction: column;
`;

export const StyledLayoutTextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  margin-top: 1rem;
`;
export const StyledLayoutTextResult = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StyledLayoutTextFooter = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  top: -30px;
`;

export const DangerousDiv = styled.div`
  p {
    margin: 0;
  }

  @media (max-width: 280px) {
    font-size: 75%;
  }
`;
