import styled from "styled-components";
import { GRAY_F9FAFB } from "../../../../constants/colors";

export const Container = styled.div`
  background-color: ${GRAY_F9FAFB};
  display: flex;
  flex-direction: column;
  height: 100vh;

  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;

  padding-top: 80px;
  text-align: center;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 240px;
  max-height: 180px;
  object-fit: fit;
  align-self: center;
`;

export const ContainerTitle = styled.div`
  margin-top: 16px;
`;

export const ContainerDetail = styled.div`
  margin-top: 8px;
`;

export const ContainerErrorCode = styled.div`
  margin-top: 16px;
`;
